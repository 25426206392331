import React from 'react';
import styles from "./Dna.module.css";

import NucleotidesList from "./NucleotidesList";
import {Button} from "../../index";

const SelectNucleotides = (props) =>{
    const {onChangeRestart, array1, array2, onChangeArray1, onChangeArray2, first, second, onChangeFirst, onChangeSecond, counter, onChangeCounter} = props;

    return (
        <>
            <div className={styles.container}>
                <Button
                    onClick={() => {
                        onChangeRestart(true);
                        onChangeCounter(counter + 1)
                    }}
                >
                    {counter === 0 ? "INIZIA" : "RICOMINCIA"}
                </Button>
                <div className={styles.section}>

                    <div className={styles.sectionLeft}>
                        <NucleotidesList array={array1} finalArray={first}
                                         onChangeFinalArray={onChangeFirst} />
                        <NucleotidesList array={array2} finalArray={second}
                                         onChangeFinalArray={onChangeSecond} />
                    </div>
                    <div className={styles.sectionRight} style={counter === 0 ? {display: "none"} : {display: ""}}>
                        <div className={styles.firstLine} style={first.length === 8 ? {justifyContent: "space-evenly" } :  {justifyContent: "flex-start"}}>
                            <NucleotidesList array={first} finalArray={array1} onChangeFinalArray={onChangeArray1} />
                        </div>
                        <div className={styles.secondLine} style={second.length === 8 ? {justifyContent: "space-evenly" } : {justifyContent: "flex-start"}}>
                            <NucleotidesList array={second} finalArray={array2} onChangeFinalArray={onChangeArray2} />
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default SelectNucleotides;