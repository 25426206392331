import React from 'react';
import DnaGame from "../components/PagesSection/Dna/DnaGame";

const Dna = (props) => {
    return (
        <>
            <DnaGame/>
        </>
    );
}

export default Dna;