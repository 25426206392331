import React from 'react';
import {MemoryGame} from "../components";

const Memory = (props) => {
    return (
        <>
            <MemoryGame/>
        </>
    );
}

export default Memory;