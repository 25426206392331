import React, {useState} from 'react';
import styles from './Card.module.css'

const Card = (props) => {
    const {id, firstChoice, secondChoice, onChangeChoice, element, img} = props;
    return (
        <>
            <div className={styles.container}>
                {element === null ? <div>  </div> :  <div onClick={() => {if (id !== firstChoice) onChangeChoice(id)}}>
                    {(firstChoice === id || secondChoice === id) ? <div className={styles.front}> <img className={styles.image} src={"images/" + img} alt={img}/> </div> :
                        <div className={styles.back} style={{backgroundImg: `url("/images/back.svg")`}} />}
                </div>}
            </div>


        </>
    );
}

export default Card;