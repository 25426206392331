import React, { useMemo } from "react";

export const AppContext = React.createContext({});

export function useAppContext() {

    return useMemo(
        () => ({
        }),
        []
    );
}
