import dna from '../nucleotides.json'
import {useEffect, useState} from "react";

const useDna = (restart, setRestart) => {
    const [array, setArray] = useState([]);
    const counter = [2, 2, 2, 2];
    const dnaList = dna;

    useEffect(() => {
        if (restart){
            setDna();
            setRestart(false)
        }
    }, [restart]);


    const setDna = () => {
        let arrayTemp = [];
        for (let i = 0; i < 8; i++){
            let rand = Math.round(Math.random() * (3));
            if(counter[rand] === 0) {
                counter.map((x, i) => {
                    if (x > 0) {
                        rand = i;
                    }
                })
            }
            arrayTemp.push(dnaList[rand]);
            counter[rand]= counter[rand]-1;

        }
        setArray(arrayTemp);
    }

        return [array, setArray];


}

export default useDna;