import React from 'react';
import {makeStyles} from "@material-ui/core";
import Scientist from "../../../assets/scientist.jpg";
import {Button} from "../../index";
import {Link} from "react-router-dom";

const useStyles = makeStyles(theme=>({
    root: {
        padding: '2rem',
        backgroundPosition: 'center',
        backgroundSize: 'cover',
        boxShadow: '0 3px 5px 2px rgba(255, 105, 135, .3)',
        color: 'black',
        height: "100vh",
        background: 'linear-gradient(0deg, rgba(80, 77, 79, 0.3), rgba(80, 77, 79, 0.3)),' + 'url('+ Scientist + ')',
        display: "flex",
        flexDirection: 'row',
        alignItems: 'flex-start',
        justifyContent: 'center'

    },
    title: {
        textAlign: 'center',
        padding: '2rem',
        fontSize: '3rem',
        fontWeight: 'bold',
        textTransform: 'uppercase',
        width: '40rem',
        borderRadius: '0.5rem',
        background:'white',
        border: '2px solid black',
        display: 'flex',
        flexFlow: 'column',
        alignItems: 'center',
        [theme.breakpoints.down('md')]: {
            width: '15rem',
            fontSize: '1.5rem',

        },
    }
}));

const Great = (props) => {
    const classes = useStyles();

    return (
        <>
            <div className={classes.root}>
                <div className={classes.title}>
                    <p style={{marginBottom: "1rem"}}>Complimenti! La scienza scorre nelle tue vene!</p>
                    <Link to='/'>
                        <Button buttonStyle={'btn--secondary'} children={'Home'}/>
                    </Link>
                </div>
            </div>

        </>
    );
}

export default Great;