import React, {useEffect, useState} from 'react';
import CardsList from "./CardsList";
import {Button, Congratulation} from "../../index";
import produce from "immer";
import useDna from "../../../hooks/useDna";
import styles from "./Memory.module.css"

const MemoryGame = (props) => {
    const [restart, setRestart] = useState(true);
    const [counter, setCounter] = useState(0);
    const [line, setLine] = useDna(restart, setRestart);
    const [firstChoice, setFirstChoice] = useState(null);
    const [secondChoice, setSecondChoice] = useState(null);
    const [guess, setGuess] = useState(0) /*CARTE INDOVINATE*/

    useEffect(() => {
        if(restart) {
            setFirstChoice(null);
            setSecondChoice(null);
            setGuess(0);
        }
    }, [restart])

    const controlMemory = () => {
        setTimeout(() =>{
                if(line[firstChoice] === line[secondChoice]){
                    setLine(produce(line, draft => {
                        draft[firstChoice].value = null;
                        draft[secondChoice].value = null;
                    }))
                    setGuess(guess+1)
                }
                    setFirstChoice(null);
                    setSecondChoice(null);

        }, 700)
    }


    return (
            <>

                    {guess !== 4 ?

                        <div className={styles.container}>
                            <Button onClick={() => {
                                setRestart(true);
                                setCounter(counter+1)
                            }} children={counter === 0 ? "Inizia" : "Ricomincia"}/>

                            <CardsList line={line}
                                       firstChoice={firstChoice}
                                       secondChoice={secondChoice}
                                       onChangeFirstChoice={setFirstChoice}
                                       onChangeSecondChoice={setSecondChoice}
                            />

                            {firstChoice !== null && secondChoice !== null ? controlMemory() : null}

                        </div>
                        : <Congratulation/>
                    }


            </>
        );

}

export default MemoryGame;