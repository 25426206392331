import React from 'react';
import styles from "./Home.module.css";
import {Link} from "react-router-dom";
import {Button} from "../components";
import Dna from "../assets/dna.jpg"
import Memory from "../assets/memory.jpg"

const Home = (props) => {
    return (
        <>
            <div className={styles.container}>
                <div className={styles.section} style={{backgroundImage: `url(${Dna})`, backgroundRepeat: "no-repeat", backgroundSize:"cover", backgroundPosition: "center"}}>
                    <Link to='/dna'>
                        <Button children={"Dna"}/>
                    </Link>
                </div>
                <div className={styles.section} style={{backgroundImage: `url(${Memory})`, backgroundRepeat: "no-repeat", backgroundSize:"cover", backgroundPosition: "center"}}>
                    <Link to='/memory'>
                        <Button children={"Memory"}/>
                    </Link>
                </div>
            </div>
        </>
    );
}

export default Home;