import React from 'react';
import styles from "./Dna.module.css";

const NucleotidesList = (props) => {
    const {array, finalArray, onChangeFinalArray} = props;

    return (
        <>
            <div className={styles.column}>
                {array !== undefined ? array.map((x, i) => {
                    return <div className={styles.nucleotide} style={{background: x.color}} onClick={() => finalArray.length < 8 ?
                        (onChangeFinalArray(finalArray.concat(x)), array.splice(i, 1)) : null}>{x.value}</div>
                }) : null}
            </div>
        </>
    );
}

export default NucleotidesList;