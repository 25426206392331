import React from 'react';
import Error from "./Error";
import Great from "./Great";

const checkNucleotide = (a, b) => {

    for (let i = 0; i < a.length; i++){
        if(a[i].value === "C" && b[i].value !== "G") {
            return false;
        }
        else if (a[i].value === "G" && b[i].value !== "C") {
            return false;
        }
        else if (a[i].value === "A" && b[i].value !== "T") {
            return false;
        }
        else if (a[i].value === "T" && b[i].value !== "A") {
            return false;
        }
    }
    return true;


}

const ControlNucleotides = (props) => {
    const {first, second} = props;
    return (
        <>
            {!checkNucleotide(first, second) ? <Error/> : <Great/>}
        </>
    );
}

export default ControlNucleotides;