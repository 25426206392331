import React, {useEffect, useState} from 'react';
import useDna from "../../../hooks/useDna";
import {ControlNucleotides, SelectNucleotides} from "../../index";



const DnaGame = (props) => {
    const [restart, setRestart] = useState(false);
    const [array1, setArray1] = useDna(restart, setRestart);
    const [array2, setArray2] = useDna(restart, setRestart);
    const [firstNucleotides, setFirstNucleotides] = useState([]);
    const [secondNucleotides, setSecondNucleotides] = useState([]);
    const [counter, setCounter] = useState(0)

    useEffect(() => {
        if(restart){
            setFirstNucleotides([]);
            setSecondNucleotides([]);
        }

    }, [restart])

    return (
        <>
            {(firstNucleotides.length === 8 && secondNucleotides.length === 8) ? <ControlNucleotides first={firstNucleotides} second={secondNucleotides}/> :
                <SelectNucleotides onChangeRestart={setRestart} array1={array1} array2={array2} onChangeArray1={setArray1}
                                   onChangeArray2={setArray2} first={firstNucleotides} second={secondNucleotides}
                                   onChangeFirst={setFirstNucleotides} onChangeSecond={setSecondNucleotides} counter={counter} onChangeCounter={setCounter}/> }
        </>
    );
}

export default DnaGame;