import './App.css';
import {Home, ScrollToTop, Dna, Memory} from "./pages";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { AppContext, useAppContext } from "./context/AppContext";

//TODO: cambiare font
function App() {
    const appData = useAppContext();
  return (
    <>
        <AppContext.Provider value={appData}>
        <Router>
            <ScrollToTop>
                <Switch>
                    <Route exact path="/">
                        <Home/>
                    </Route>
                    <Route exact path="/dna">
                        <Dna/>
                    </Route>
                    <Route exact path="/memory">
                        <Memory/>
                    </Route>
                </Switch>
            </ScrollToTop>
        </Router>

        </AppContext.Provider>
    </>
  );
}

export default App;
