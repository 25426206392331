import React from 'react';
import {Card} from "../../index";
import styles from "./Memory.module.css"

const CardsList = (props) => {
    const {line, firstChoice, secondChoice, onChangeFirstChoice, onChangeSecondChoice} = props;
    return (
        <>
            <div className={styles.section}>
                {line !== undefined ? line.map((x, i) => {
                    return <Card id={i} element={x.value} firstChoice={firstChoice}
                                 secondChoice={secondChoice}
                                 onChangeChoice={firstChoice === null ? onChangeFirstChoice : onChangeSecondChoice} img={x.img}/>
                }) : null}
            </div>

        </>
    )

}

export default CardsList;